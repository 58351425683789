/* Create the namespace
 */
var Kamine = function() {};

Kamine.prototype = {
	initMenu: function() {
		var menuBtn = document.querySelector('.menu-button'),
			site	= document.querySelector('.site'),
			img		= menuBtn.querySelector('img'),
			span	= menuBtn.querySelector('span');
			
		var moveMenuInOut = function(e) {
			// Better revent default actions, in case this method needs to be applied to a link
			e.preventDefault();

			if( site.classList.contains('active') ) {
				site.classList.remove('active');
			} else {
				site.classList.add('active');
			}	
		};
			
		img.addEventListener('click', moveMenuInOut, false);
		span.addEventListener('click', moveMenuInOut, false);
	}
};

jQuery('document').ready(function() {
	var kamine = new Kamine();
	kamine.initMenu();
	
	var hash = window.location.hash;
	var h3 = jQuery('.accordion-button');
	$('.accordion-button.active').next().show();
	
	var react = function(elem) {
		var $this = $(elem),
				$next = $this.next(),
				$span = $this.find('span'),
				$siblings = $next.siblings('.accordion');
				
		$next.slideToggle();
		//$siblings.prev().find('span').html('+');
		//$siblings.slideUp();
		
		if ( $span.html() === '+' ) {
			$span.html('-');
		} else {
			$span.html('+');
		}
	};

	h3.on('click', function() {
		react(this);
	});


	/* Scroll to top */

	var $toTopLink = $('a[href="#top"]').hide();
	var is_visible = false;
	
  $toTopLink.click(function(event){ 
    $('html, body').animate({scrollTop: 0}, 'slow');
    event.preventDefault();
  });

	$(window).scroll(function() {
		if($(this).scrollTop() === 0) {
			$toTopLink.stop(true, true).fadeOut();
			is_visible = false;
		} else if(!is_visible) {
			$toTopLink.stop(true, true).fadeIn();
			is_visible = true;
		}
	});
});
